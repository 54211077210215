import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "89159108-278f-4d48-a7e0-16224af0a2aa"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label89159108278f4d48A7e016224af0a2aa(props: any) {
  return (
    <DefaultLogoA02a36baBd8142abB3116fe3fe360ba1 parentTag="Label89159108278f4d48A7e016224af0a2aa" {...props} />
  );
}

// id: "3eb83dd0-7b7d-4322-8274-076046dd31d2"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages3eb83dd07b7d43228274076046dd31d2(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages3eb83dd07b7d43228274076046dd31d2 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f parentTag="HeaderDbbbcd735bf6487bB73150cd350c4297" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav7e3eb7309c134650Ba79Fd03adb94a35", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="Footer133f2c8e3c534c93A6f5A83d189b03a6" {...props} />}
    </div>
  );
}

// id: "71c2075a-2110-4d50-b787-b371818a6683"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage71c2075a21104d50B787B371818a6683(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage71c2075a21104d50B787B371818a6683`}>
      {props["header"] || <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header6158fc1f49334532863d5eef6dc332b3" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="Footer156a117850944aba8d05805c0d783e2f" {...props} />}
    </div>
  );
}

// id: "a02a36ba-bd81-42ab-b311-6fe3fe360ba1"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoA02a36baBd8142abB3116fe3fe360ba1(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoA02a36baBd8142abB3116fe3fe360ba1 logo`} alt="logo" />
  );
}

// id: "3362c038-b4a8-47a0-8d43-c96a4401608c"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage3362c038B4a847a08d43C96a4401608c(props: any) {
  return (
    <SecondaryPage71c2075a21104d50B787B371818a6683 parentTag="JoinUsPage3362c038B4a847a08d43C96a4401608c" header={<SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header3cff4f2f44fa4af8A5c7C7568bc344c8" title="Join Us" />} {...props} />
  );
}

// id: "699c18cd-e497-4012-9cdc-90dafbf8ea1b"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage699c18cdE49740129cdc90dafbf8ea1b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage699c18cdE49740129cdc90dafbf8ea1b`}>
      {props["header"] || <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header168600fc59c2468785d8Cf6fe3399d84" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="Footer56e6faef46e74e30Aca68ead0b3af4f7" {...props} />}
    </div>
  );
}

// id: "93019687-8e77-482c-933f-2ba678045fb0"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault930196878e77482c933f2ba678045fb0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault930196878e77482c933f2ba678045fb0 page`}>
      {props["header"] || <MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f parentTag="Header5c56e81fD8f04b3e9ad59033059a4d9f" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="Footer67553298Ab9e47bb86477178e5460de2" {...props} />}
    </div>
  );
}

// id: "527439f8-cfc8-45dd-88bd-06e7d32edd3c"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage527439f8Cfc845dd88bd06e7d32edd3c(props: any) {
  return (
    <SecondaryPage71c2075a21104d50B787B371818a6683 parentTag="ResourcesPage527439f8Cfc845dd88bd06e7d32edd3c" header={<SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="HeaderEa549741Dd8b4c31Ab372b1fde251c20" title="Resources" />} {...props} />
  );
}

// id: "ef2b7d80-8a8a-441a-83c0-6c623d93385a"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteEf2b7d808a8a441a83c06c623d93385a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteEf2b7d808a8a441a83c06c623d93385a`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "6b1e188c-3d38-4aa6-a5c3-b67dc98240db"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href6b1e188c3d384aa6A5c3B67dc98240db = "/";

// id: "b82e1350-eb96-4cba-b924-b4d633c5ae3b"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterB82e1350Eb964cbaB924B4d633c5ae3b(props: any) {
  return (
    <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="FooterB82e1350Eb964cbaB924B4d633c5ae3b" {...props} />
  );
}

// id: "fe6c979d-2a7f-4a86-8382-191c2017b0e6"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleFe6c979d2a7f4a868382191c2017b0e6 = "Leveling";

// id: "8f545343-3bfa-41dc-9431-8b238741f886"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages8f5453433bfa41dc94318b238741f886(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages8f5453433bfa41dc94318b238741f886`}>
      {props.children}
    </div>
  );
}

// id: "f5543534-afd4-4d19-9549-58a47ae60a6e"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderF5543534Afd44d19954958a47ae60a6e(props: any) {
  return (
    <ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa parentTag="DefaultHeaderF5543534Afd44d19954958a47ae60a6e" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "923b02ea-cf0f-4d8a-a276-f324d55f0f6f"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f(props: any) {
  return (
    <ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa parentTag="MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f" header-top={<DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b parentTag="HeaderTop630cbdba33f94b63826f987f47ff2eb5" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "0f76cab9-5c32-4cb4-9233-09b8cd33ec0c"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody0f76cab95c324cb4923309b8cd33ec0c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody0f76cab95c324cb4923309b8cd33ec0c page-body`}>
      {props["marketingCards"] || <MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 parentTag="MarketingCards4e352a07C22c4d9f9c3a36c4d090b1eb" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "07b08a52-4600-48d2-8e62-0e085cc7e853"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage07b08a52460048d28e620e085cc7e853(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage07b08a52460048d28e620e085cc7e853 page`}>
      {props["header"] || <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header768ea04dD5954b19A9e25bea5de6861a" title="About Us" {...props} />}
      {props["body"] || <MarketingBody0f76cab95c324cb4923309b8cd33ec0c parentTag="BodyA2ffd24b76f143048cd7Fe42ee8ad6d6" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="FooterB664451b7f274f159cc0Bf0c7719f523" {...props} />}
    </div>
  );
}

// id: "0e8ba37b-f9ad-461a-a584-cb7cfdd52afa"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo3bb6758b889845d1B08d819192f894a4", parentTag)} label={<DefaultLogoA02a36baBd8142abB3116fe3fe360ba1 parentTag="Label64bd4e2fD9a54a0087761ef3e0e4e90e" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation68917b101ddb4a058159Befffbf1cf09", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "df97c57c-b326-4c81-92cf-5d430ce5c00b"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo202df3531b2c42c3865a67623d36f621", parentTag)} label={<DefaultLogoA02a36baBd8142abB3116fe3fe360ba1 parentTag="Label3b24a68755874189B373Cd9368409334" alt="logo" />} className="linked-logo" url="/" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation6413825e4dfa49f09b4e0ba603f2ee45", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "133f2c8e-3c53-4c93-a6f5-a83d189b03a6"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "3eb83dd0-7b7d-4322-8274-076046dd31d2"
export function Footer133f2c8e3c534c93A6f5A83d189b03a6(props: any) {
  return (
    <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="Footer133f2c8e3c534c93A6f5A83d189b03a6" {...props} />
  );
}

// id: "bbd2555e-af98-49fc-adfc-c1c11b9164bd"
// title: ""
// type: :reference
// key: "header"
// parent_id: "09b626e6-4a4c-418d-8a0f-a3dbea849cff"
export function HeaderBbd2555eAf9849fcAdfcC1c11b9164bd(props: any) {
  return (
    <MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f parentTag="HeaderBbd2555eAf9849fcAdfcC1c11b9164bd" {...props} />
  );
}

// id: "ab00d197-f2fb-4efb-821d-09e922230eab"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b18f26c6-5a25-4b10-8767-06a690036893"
export function HeaderAb00d197F2fb4efb821d09e922230eab(props: any) {
  return (
    <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="HeaderAb00d197F2fb4efb821d09e922230eab" title="Join Us" {...props} />
  );
}

// id: "768ea04d-d595-4b19-a9e2-5bea5de6861a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "07b08a52-4600-48d2-8e62-0e085cc7e853"
export function Header768ea04dD5954b19A9e25bea5de6861a(props: any) {
  return (
    <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header768ea04dD5954b19A9e25bea5de6861a" title="About Us" {...props} />
  );
}

// id: "0569e200-111e-4cc5-b56b-9d4f08063a02"
// title: ""
// type: :text
// key: "alt"
// parent_id: "a02a36ba-bd81-42ab-b311-6fe3fe360ba1"
export const Alt0569e200111e4cc5B56b9d4f08063a02 = "logo";

// id: "649173ac-89f9-419b-b9e0-5b45ca83a3b7"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "630cbdba-33f9-4b63-826f-987f47ff2eb5"
export const NavMenuSlug649173ac89f9419bB9e05b45ca83a3b7 = "members-primary-nav";

// id: "f56399dd-eb62-402c-a860-78ed544ff24a"
// title: ""
// type: :text
// key: "title"
// parent_id: "708c43f1-220c-43d0-bb1b-48f3743b241a"
export const TitleF56399ddEb62402cA86078ed544ff24a = "Secondary Marketing Title 2";

// id: "3b24a687-5587-4189-b373-cd9368409334"
// title: ""
// type: :reference
// key: "label"
// parent_id: "202df353-1b2c-42c3-865a-67623d36f621"
export function Label3b24a68755874189B373Cd9368409334(props: any) {
  return (
    <DefaultLogoA02a36baBd8142abB3116fe3fe360ba1 parentTag="Label3b24a68755874189B373Cd9368409334" {...props} />
  );
}

// id: "b18f26c6-5a25-4b10-8767-06a690036893"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesB18f26c65a254b10876706a690036893(props: any) {
  return (
    <SecondaryPage71c2075a21104d50B787B371818a6683 parentTag="SignupPagesB18f26c65a254b10876706a690036893" header={<SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="HeaderAb00d197F2fb4efb821d09e922230eab" title="Join Us" />} {...props} />
  );
}

// id: "bddd8308-5cfc-47d0-b1cd-f74d5c46e263"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-posts2" {...props} />
  );
}

// id: "67553298-ab9e-47bb-8647-7178e5460de2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "93019687-8e77-482c-933f-2ba678045fb0"
export function Footer67553298Ab9e47bb86477178e5460de2(props: any) {
  return (
    <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="Footer67553298Ab9e47bb86477178e5460de2" {...props} />
  );
}

// id: "0702b2b6-19ec-450f-9238-96d26b55b5e6"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "bddd8308-5cfc-47d0-b1cd-f74d5c46e263"
export const ButtonClass0702b2b619ec450f923896d26b55b5e6 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "156a1178-5094-4aba-8d05-805c0d783e2f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "71c2075a-2110-4d50-b787-b371818a6683"
export function Footer156a117850944aba8d05805c0d783e2f(props: any) {
  return (
    <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="Footer156a117850944aba8d05805c0d783e2f" {...props} />
  );
}

// id: "68917b10-1ddb-4a05-8159-befffbf1cf09"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "0e8ba37b-f9ad-461a-a584-cb7cfdd52afa"
export function Navigation68917b101ddb4a058159Befffbf1cf09(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation68917b101ddb4a058159Befffbf1cf09", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "0d85b16f-ad18-48ca-a3e7-3ed76569487a"
// title: ""
// type: :text
// key: "title"
// parent_id: "071575d2-30b7-4d21-b836-00e18eaa2b9d"
export const Title0d85b16fAd1848caA3e73ed76569487a = "Secondary Marketing Title 1";

// id: "bb6b2f81-82df-420a-b9e4-9b4bd16eb81a"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "71717e0f-94df-4232-9f36-410bfaf9fb5e"
export const ContentSlugBb6b2f8182df420aB9e49b4bd16eb81a = "home-page-quote";

// id: "d129e3a3-2bc9-49d5-9e47-71b2d00f4cf7"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "a2ffd24b-76f1-4304-8cd7-fe42ee8ad6d6"
export const PostSlugD129e3a32bc949d59e4771b2d00f4cf7 = "about-us-posts";

// id: "b788f542-1872-46f4-bd45-a69f7869279a"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a hero`}>
      <div className="title">
        {props["title"] || Title57823aedA915499dBc5e0c259f26a881}
      </div>
    </div>
  );
}

// id: "63f9b514-f5ba-4097-8766-afd4f559874d"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader63f9b514F5ba40978766Afd4f559874d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader63f9b514F5ba40978766Afd4f559874d site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b parentTag="HeaderTop649c336597064e508c236e8445a71150" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 parentTag="Hero4d14adfdA01740beB60eDd784a8d9d68" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "dd3f6204-ebff-4530-97a9-03a5e4326781"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "afd0152e-8e8c-4fe4-8421-6d3ab7e2651a"
export function FooterDd3f6204Ebff453097a903a5e4326781(props: any) {
  return (
    <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="FooterDd3f6204Ebff453097a903a5e4326781" {...props} />
  );
}

// id: "7e3eb730-9c13-4650-ba79-fd03adb94a35"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "3eb83dd0-7b7d-4322-8274-076046dd31d2"
export function SecondaryNav7e3eb7309c134650Ba79Fd03adb94a35(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav7e3eb7309c134650Ba79Fd03adb94a35", parentTag)} {...props} />
  );
}

// id: "5fff56cb-6c94-460a-bc43-bc79c30a8ad2"
// title: ""
// type: :html
// key: "logo"
// parent_id: "4b8dcb2b-f082-47c5-9f73-f8034dd156a4"
export function Logo5fff56cb6c94460aBc43Bc79c30a8ad2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo5fff56cb6c94460aBc43Bc79c30a8ad2 logo`} />
  );
}

// id: "649c3365-9706-4e50-8c23-6e8445a71150"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "63f9b514-f5ba-4097-8766-afd4f559874d"
export function HeaderTop649c336597064e508c236e8445a71150(props: any) {
  return (
    <DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b parentTag="HeaderTop649c336597064e508c236e8445a71150" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "9085a341-a15f-4e94-bdd1-6734e8c265cf"
// title: ""
// type: :text
// key: "title"
// parent_id: "6158fc1f-4933-4532-863d-5eef6dc332b3"
export const Title9085a341A15f4e94Bdd16734e8c265cf = "Secondary Page";

// id: "e5109b3f-a967-483a-b4d9-0d9d21e597f5"
// title: ""
// type: :text
// key: "title"
// parent_id: "3cff4f2f-44fa-4af8-a5c7-c7568bc344c8"
export const TitleE5109b3fA967483aB4d90d9d21e597f5 = "Join Us";

// id: "42f342ea-8e2c-4649-a23a-65768ee7c6aa"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header42f342ea8e2c4649A23a65768ee7c6aa(props: any) {
  return (
    <MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f parentTag="Header42f342ea8e2c4649A23a65768ee7c6aa" {...props} />
  );
}

// id: "3631cc8a-9802-40bf-97f8-26bec5585ade"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "4b8dcb2b-f082-47c5-9f73-f8034dd156a4"
export function Links3631cc8a980240bf97f826bec5585ade(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links3631cc8a980240bf97f826bec5585ade", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "6158fc1f-4933-4532-863d-5eef6dc332b3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "71c2075a-2110-4d50-b787-b371818a6683"
export function Header6158fc1f49334532863d5eef6dc332b3(props: any) {
  return (
    <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header6158fc1f49334532863d5eef6dc332b3" title="Secondary Page" {...props} />
  );
}

// id: "73a9465f-d49c-4dcd-a8c2-a610bc5b38eb"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "4b8dcb2b-f082-47c5-9f73-f8034dd156a4"
export function Copyright73a9465fD49c4dcdA8c2A610bc5b38eb(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright73a9465fD49c4dcdA8c2A610bc5b38eb", parentTag)} {...props} />
  );
}

// id: "e39f1002-73a6-4e5e-babf-f206888c718f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "641ae056-9abd-48f2-9d3f-9c71d8e5d235"
export function FooterE39f100273a64e5eBabfF206888c718f(props: any) {
  return (
    <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="FooterE39f100273a64e5eBabfF206888c718f" {...props} />
  );
}

// id: "630cbdba-33f9-4b63-826f-987f47ff2eb5"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "923b02ea-cf0f-4d8a-a276-f324d55f0f6f"
export function HeaderTop630cbdba33f94b63826f987f47ff2eb5(props: any) {
  return (
    <DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b parentTag="HeaderTop630cbdba33f94b63826f987f47ff2eb5" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "20da0987-8c1f-4ef3-ab07-ba8c258da05f"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "4d14adfd-a017-40be-b60e-dd784a8d9d68"
export const WidgetId20da09878c1f4ef3Ab07Ba8c258da05f = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "776c1837-5109-4a04-9913-6357061672e7"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "3631cc8a-9802-40bf-97f8-26bec5585ade"
export const NavMenuSlug776c183751094a0499136357061672e7 = "footer-nav";

// id: "7a335c7f-7ca3-419c-ac40-81ae59a0a1b2"
// title: ""
// type: :text
// key: "title"
// parent_id: "62383db0-8bbe-48fc-b06e-0fcd3644f590"
export const Title7a335c7f7ca3419cAc4081ae59a0a1b2 = "Leveling";

// id: "4b8dcb2b-f082-47c5-9f73-f8034dd156a4"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links3631cc8a980240bf97f826bec5585ade", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo5fff56cb6c94460aBc43Bc79c30a8ad2 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright73a9465fD49c4dcdA8c2A610bc5b38eb", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowBe26cd667f0144048e11Ccb9cea431c3", parentTag)} {...props} />}
    </div>
  );
}

// id: "708c43f1-220c-43d0-bb1b-48f3743b241a"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "071575d2-30b7-4d21-b836-00e18eaa2b9d"
export function Hero708c43f1220c43d0Bb1b48f3743b241a(props: any) {
  return (
    <SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a parentTag="Hero708c43f1220c43d0Bb1b48f3743b241a" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "534828a5-5045-4717-80b2-a71ee075785f"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "bddd8308-5cfc-47d0-b1cd-f74d5c46e263"
export const PostSlug534828a55045471780b2A71ee075785f = "home-page-posts2";

// id: "3bb6758b-8898-45d1-b08d-819192f894a4"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "0e8ba37b-f9ad-461a-a584-cb7cfdd52afa"
export function LinkedLogo3bb6758b889845d1B08d819192f894a4(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo3bb6758b889845d1B08d819192f894a4", parentTag)} label={<DefaultLogoA02a36baBd8142abB3116fe3fe360ba1 parentTag="Label64bd4e2fD9a54a0087761ef3e0e4e90e" alt="logo" />} {...props} />
  );
}

// id: "6413825e-4dfa-49f0-9b4e-0ba603f2ee45"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "df97c57c-b326-4c81-92cf-5d430ce5c00b"
export function Navigation6413825e4dfa49f09b4e0ba603f2ee45(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation6413825e4dfa49f09b4e0ba603f2ee45", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "3cff4f2f-44fa-4af8-a5c7-c7568bc344c8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "3362c038-b4a8-47a0-8d43-c96a4401608c"
export function Header3cff4f2f44fa4af8A5c7C7568bc344c8(props: any) {
  return (
    <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header3cff4f2f44fa4af8A5c7C7568bc344c8" title="Join Us" {...props} />
  );
}

// id: "7a617a48-ac7e-4082-bf63-df714cae41f2"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "649c3365-9706-4e50-8c23-6e8445a71150"
export const WidgetId7a617a48Ac7e4082Bf63Df714cae41f2 = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "470f706c-fcae-46f8-8f9f-83b947630075"
// title: ""
// type: :text
// key: "class"
// parent_id: "202df353-1b2c-42c3-865a-67623d36f621"
export const Class470f706cFcae46f88f9f83b947630075 = "linked-logo";

// id: "8a0e103b-b2f9-42b6-a0b4-c7872849c753"
// title: ""
// type: :text
// key: "title"
// parent_id: "ea549741-dd8b-4c31-ab37-2b1fde251c20"
export const Title8a0e103bB2f942b6A0b4C7872849c753 = "Resources";

// id: "641ae056-9abd-48f2-9d3f-9c71d8e5d235"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage641ae0569abd48f29d3f9c71d8e5d235(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage641ae0569abd48f29d3f9c71d8e5d235`}>
      {props["header"] || <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header62383db08bbe48fcB06e0fcd3644f590" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="FooterE39f100273a64e5eBabfF206888c718f" {...props} />}
    </div>
  );
}

// id: "5c56e81f-d8f0-4b3e-9ad5-9033059a4d9f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "93019687-8e77-482c-933f-2ba678045fb0"
export function Header5c56e81fD8f04b3e9ad59033059a4d9f(props: any) {
  return (
    <MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f parentTag="Header5c56e81fD8f04b3e9ad59033059a4d9f" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "cdd1ae51-ba48-4a0f-8242-bedeee2b4646"
// title: ""
// type: :text
// key: "title"
// parent_id: "f5543534-afd4-4d19-9549-58a47ae60a6e"
export const TitleCdd1ae51Ba484a0f8242Bedeee2b4646 = null;

// id: "b2429ff4-9dab-4bba-97ac-524b884c5419"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "071575d2-30b7-4d21-b836-00e18eaa2b9d"
export function HeaderTopB2429ff49dab4bba97ac524b884c5419(props: any) {
  return (
    <DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b parentTag="HeaderTopB2429ff49dab4bba97ac524b884c5419" {...props} />
  );
}

// id: "be26cd66-7f01-4404-8e11-ccb9cea431c3"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "4b8dcb2b-f082-47c5-9f73-f8034dd156a4"
export function GroupflowBe26cd667f0144048e11Ccb9cea431c3(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowBe26cd667f0144048e11Ccb9cea431c3", parentTag)} {...props} />
  );
}

// id: "5d8e8b29-d46b-4017-a809-8bafe79daa30"
// title: ""
// type: :text
// key: "href"
// parent_id: "aa76e7af-6909-4a1a-98d7-4946bfc94452"
export const Href5d8e8b29D46b4017A8098bafe79daa30 = "/join";

// id: "6cc1b7dc-22a6-4e0b-9526-b9d3866e88a8"
// title: ""
// type: :text
// key: "title"
// parent_id: "768ea04d-d595-4b19-a9e2-5bea5de6861a"
export const Title6cc1b7dc22a64e0b9526B9d3866e88a8 = "About Us";

// id: "8d05f90c-0ce3-4026-9d22-e2934b16d1ad"
// title: ""
// type: :text
// key: "title"
// parent_id: "ab00d197-f2fb-4efb-821d-09e922230eab"
export const Title8d05f90c0ce340269d22E2934b16d1ad = "Join Us";

// id: "09b626e6-4a4c-418d-8a0f-a3dbea849cff"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages09b626e64a4c418d8a0fA3dbea849cff(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages09b626e64a4c418d8a0fA3dbea849cff`}>
      {props["header"] || <MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f parentTag="HeaderBbd2555eAf9849fcAdfcC1c11b9164bd" {...props} />}
      {props.children}
    </div>
  );
}

// id: "dbbbcd73-5bf6-487b-b731-50cd350c4297"
// title: ""
// type: :reference
// key: "header"
// parent_id: "3eb83dd0-7b7d-4322-8274-076046dd31d2"
export function HeaderDbbbcd735bf6487bB73150cd350c4297(props: any) {
  return (
    <MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f parentTag="HeaderDbbbcd735bf6487bB73150cd350c4297" {...props} />
  );
}

// id: "202df353-1b2c-42c3-865a-67623d36f621"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "df97c57c-b326-4c81-92cf-5d430ce5c00b"
export function LinkedLogo202df3531b2c42c3865a67623d36f621(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo202df3531b2c42c3865a67623d36f621", parentTag)} label={<DefaultLogoA02a36baBd8142abB3116fe3fe360ba1 parentTag="Label3b24a68755874189B373Cd9368409334" alt="logo" />} className="linked-logo" url="/" {...props} />
  );
}

// id: "f0a636a6-265d-4eec-80fa-a6f503f6b0da"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "0e8ba37b-f9ad-461a-a584-cb7cfdd52afa"
export const BackgroundImageF0a636a6265d4eec80faA6f503f6b0da = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "56e6faef-46e7-4e30-aca6-8ead0b3af4f7"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "699c18cd-e497-4012-9cdc-90dafbf8ea1b"
export function Footer56e6faef46e74e30Aca68ead0b3af4f7(props: any) {
  return (
    <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="Footer56e6faef46e74e30Aca68ead0b3af4f7" {...props} />
  );
}

// id: "8c30744a-283d-4d7c-afa6-c6563dd423a7"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "f5543534-afd4-4d19-9549-58a47ae60a6e"
export const NavMenuSlug8c30744a283d4d7cAfa6C6563dd423a7 = "marketing-primary-nav";

// id: "df7d96f3-3faf-49c6-8e14-4f5edfde06db"
// title: ""
// type: :text
// key: "title"
// parent_id: "168600fc-59c2-4687-85d8-cf6fe3399d84"
export const TitleDf7d96f33faf49c68e144f5edfde06db = "Public Events";

// id: "09a2ade9-23a9-4cc9-bf3a-00cd694d7aa1"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "5c56e81f-d8f0-4b3e-9ad5-9033059a4d9f"
export const NavMenuSlug09a2ade923a94cc9Bf3a00cd694d7aa1 = "members-primary-nav";

// id: "8053e75f-937c-4f6c-b572-7b44ea24fad5"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "68917b10-1ddb-4a05-8159-befffbf1cf09"
export const NavMenuSlug8053e75f937c4f6cB5727b44ea24fad5 = "members-primary-nav";

// id: "6010d128-cf2b-4d49-b64b-8cca6eb90db5"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("ButtonAa76e7af69094a1a98d74946bfc94452", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "18cb0782-a544-4497-a159-b3e6c5e0a90b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "afd0152e-8e8c-4fe4-8421-6d3ab7e2651a"
export function Header18cb0782A5444497A159B3e6c5e0a90b(props: any) {
  return (
    <HomeHeader63f9b514F5ba40978766Afd4f559874d parentTag="Header18cb0782A5444497A159B3e6c5e0a90b" {...props} />
  );
}

// id: "8c550e51-40fd-4727-a498-8b2b2117f884"
// title: ""
// type: :reference
// key: "body"
// parent_id: "afd0152e-8e8c-4fe4-8421-6d3ab7e2651a"
export function Body8c550e5140fd4727A4988b2b2117f884(props: any) {
  return (
    <MarketingBody0f76cab95c324cb4923309b8cd33ec0c parentTag="Body8c550e5140fd4727A4988b2b2117f884" {...props} />
  );
}

// id: "4e352a07-c22c-4d9f-9c3a-36c4d090b1eb"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "0f76cab9-5c32-4cb4-9233-09b8cd33ec0c"
export function MarketingCards4e352a07C22c4d9f9c3a36c4d090b1eb(props: any) {
  return (
    <MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263 parentTag="MarketingCards4e352a07C22c4d9f9c3a36c4d090b1eb" postSlug="home-page-posts2" {...props} />
  );
}

// id: "57823aed-a915-499d-bc5e-0c259f26a881"
// title: ""
// type: :text
// key: "title"
// parent_id: "b788f542-1872-46f4-bd45-a69f7869279a"
export const Title57823aedA915499dBc5e0c259f26a881 = "Default Title";

// id: "a2ffd24b-76f1-4304-8cd7-fe42ee8ad6d6"
// title: ""
// type: :reference
// key: "body"
// parent_id: "07b08a52-4600-48d2-8e62-0e085cc7e853"
export function BodyA2ffd24b76f143048cd7Fe42ee8ad6d6(props: any) {
  return (
    <MarketingBody0f76cab95c324cb4923309b8cd33ec0c parentTag="BodyA2ffd24b76f143048cd7Fe42ee8ad6d6" postSlug="about-us-posts" {...props} />
  );
}

// id: "ebb4a3e2-d021-41bb-9043-703a8d67c257"
// title: ""
// type: :text
// key: "label"
// parent_id: "aa76e7af-6909-4a1a-98d7-4946bfc94452"
export const LabelEbb4a3e2D02141bb9043703a8d67c257 = "Join Us";

// id: "3bfdf429-2417-4fdd-a74b-03015781ab58"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "6413825e-4dfa-49f0-9b4e-0ba603f2ee45"
export const NavMenuSlug3bfdf42924174fddA74b03015781ab58 = "marketing-primary-nav";

// id: "071575d2-30b7-4d21-b836-00e18eaa2b9d"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b parentTag="HeaderTopB2429ff49dab4bba97ac524b884c5419" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a parentTag="Hero708c43f1220c43d0Bb1b48f3743b241a" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "b8a496c3-18a8-4ac7-9722-a6bf1c0d8fa2"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "afd0152e-8e8c-4fe4-8421-6d3ab7e2651a"
export function WelcomeBackB8a496c318a84ac79722A6bf1c0d8fa2(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackB8a496c318a84ac79722A6bf1c0d8fa2", parentTag)} {...props} />
  );
}

// id: "62383db0-8bbe-48fc-b06e-0fcd3644f590"
// title: ""
// type: :reference
// key: "header"
// parent_id: "641ae056-9abd-48f2-9d3f-9c71d8e5d235"
export function Header62383db08bbe48fcB06e0fcd3644f590(props: any) {
  return (
    <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header62383db08bbe48fcB06e0fcd3644f590" title="Leveling" {...props} />
  );
}

// id: "aa76e7af-6909-4a1a-98d7-4946bfc94452"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "6010d128-cf2b-4d49-b64b-8cca6eb90db5"
export function ButtonAa76e7af69094a1a98d74946bfc94452(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("ButtonAa76e7af69094a1a98d74946bfc94452", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "ea549741-dd8b-4c31-ab37-2b1fde251c20"
// title: ""
// type: :reference
// key: "header"
// parent_id: "527439f8-cfc8-45dd-88bd-06e7d32edd3c"
export function HeaderEa549741Dd8b4c31Ab372b1fde251c20(props: any) {
  return (
    <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="HeaderEa549741Dd8b4c31Ab372b1fde251c20" title="Resources" {...props} />
  );
}

// id: "71717e0f-94df-4232-9f36-410bfaf9fb5e"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "afd0152e-8e8c-4fe4-8421-6d3ab7e2651a"
export function Quote71717e0f94df42329f36410bfaf9fb5e(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote71717e0f94df42329f36410bfaf9fb5e", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "1e05f3fc-aa0f-4206-86b4-044167d4ed8f"
// title: ""
// type: :text
// key: "url"
// parent_id: "202df353-1b2c-42c3-865a-67623d36f621"
export const Url1e05f3fcAa0f420686b4044167d4ed8f = "/";

// id: "9350f7e7-2ae0-44d8-9545-dd17bbc716c8"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "4e352a07-c22c-4d9f-9c3a-36c4d090b1eb"
export const PostSlug9350f7e72ae044d89545Dd17bbc716c8 = "home-page-posts2";

// id: "afd0152e-8e8c-4fe4-8421-6d3ab7e2651a"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageAfd0152e8e8c4fe484216d3ab7e2651a(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageAfd0152e8e8c4fe484216d3ab7e2651a page`}>
      {props["header"] || <HomeHeader63f9b514F5ba40978766Afd4f559874d parentTag="Header18cb0782A5444497A159B3e6c5e0a90b" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackB8a496c318a84ac79722A6bf1c0d8fa2", parentTag)} {...props} />}
      {props["body"] || <MarketingBody0f76cab95c324cb4923309b8cd33ec0c parentTag="Body8c550e5140fd4727A4988b2b2117f884" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote71717e0f94df42329f36410bfaf9fb5e", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="FooterDd3f6204Ebff453097a903a5e4326781" {...props} />}
    </div>
  );
}

// id: "b664451b-7f27-4f15-9cc0-bf0c7719f523"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "07b08a52-4600-48d2-8e62-0e085cc7e853"
export function FooterB664451b7f274f159cc0Bf0c7719f523(props: any) {
  return (
    <DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4 parentTag="FooterB664451b7f274f159cc0Bf0c7719f523" {...props} />
  );
}

// id: "4d14adfd-a017-40be-b60e-dd784a8d9d68"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "63f9b514-f5ba-4097-8766-afd4f559874d"
export function Hero4d14adfdA01740beB60eDd784a8d9d68(props: any) {
  return (
    <HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5 parentTag="Hero4d14adfdA01740beB60eDd784a8d9d68" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "168600fc-59c2-4687-85d8-cf6fe3399d84"
// title: ""
// type: :reference
// key: "header"
// parent_id: "699c18cd-e497-4012-9cdc-90dafbf8ea1b"
export function Header168600fc59c2468785d8Cf6fe3399d84(props: any) {
  return (
    <SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d parentTag="Header168600fc59c2468785d8Cf6fe3399d84" title="Public Events" {...props} />
  );
}

// id: "1c90a5f2-be42-40b0-96b0-45413085921d"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "63f9b514-f5ba-4097-8766-afd4f559874d"
export const BackgroundImage1c90a5f2Be4240b096b045413085921d = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "a37c932c-0a4d-46b1-ac11-ea849736a4b4"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "a02a36ba-bd81-42ab-b311-6fe3fe360ba1"
export const ImageUrlA37c932c0a4d46b1Ac11Ea849736a4b4 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "e88adc3a-7742-4e94-8096-b0d788538bef"
// title: ""
// type: :text
// key: "class"
// parent_id: "6413825e-4dfa-49f0-9b4e-0ba603f2ee45"
export const ClassE88adc3a77424e948096B0d788538bef = "navigation";

// id: "64bd4e2f-d9a5-4a00-8776-1ef3e0e4e90e"
// title: ""
// type: :reference
// key: "label"
// parent_id: "3bb6758b-8898-45d1-b08d-819192f894a4"
export function Label64bd4e2fD9a54a0087761ef3e0e4e90e(props: any) {
  return (
    <DefaultLogoA02a36baBd8142abB3116fe3fe360ba1 parentTag="Label64bd4e2fD9a54a0087761ef3e0e4e90e" {...props} />
  );
}

const Components = {
  Label89159108278f4d48A7e016224af0a2aa,
  MemberPages3eb83dd07b7d43228274076046dd31d2,
  SecondaryPage71c2075a21104d50B787B371818a6683,
  DefaultLogoA02a36baBd8142abB3116fe3fe360ba1,
  JoinUsPage3362c038B4a847a08d43C96a4401608c,
  PublicEventsPage699c18cdE49740129cdc90dafbf8ea1b,
  MembersAreaDefault930196878e77482c933f2ba678045fb0,
  ResourcesPage527439f8Cfc845dd88bd06e7d32edd3c,
  PageQuoteEf2b7d808a8a441a83c06c623d93385a,
  Href6b1e188c3d384aa6A5c3B67dc98240db,
  FooterB82e1350Eb964cbaB924B4d633c5ae3b,
  TitleFe6c979d2a7f4a868382191c2017b0e6,
  AdminPages8f5453433bfa41dc94318b238741f886,
  DefaultHeaderF5543534Afd44d19954958a47ae60a6e,
  MembersAreaHeader923b02eaCf0f4d8aA276F324d55f0f6f,
  MarketingBody0f76cab95c324cb4923309b8cd33ec0c,
  AboutUsPage07b08a52460048d28e620e085cc7e853,
  ShortHeader0e8ba37bF9ad461aA584Cb7cfdd52afa,
  DefaultHeaderTopDf97c57cB3264c8192cf5d430ce5c00b,
  Footer133f2c8e3c534c93A6f5A83d189b03a6,
  HeaderBbd2555eAf9849fcAdfcC1c11b9164bd,
  HeaderAb00d197F2fb4efb821d09e922230eab,
  Header768ea04dD5954b19A9e25bea5de6861a,
  Alt0569e200111e4cc5B56b9d4f08063a02,
  NavMenuSlug649173ac89f9419bB9e05b45ca83a3b7,
  TitleF56399ddEb62402cA86078ed544ff24a,
  Label3b24a68755874189B373Cd9368409334,
  SignupPagesB18f26c65a254b10876706a690036893,
  MarketingCardsBddd83085cfc47d0B1cdF74d5c46e263,
  Footer67553298Ab9e47bb86477178e5460de2,
  ButtonClass0702b2b619ec450f923896d26b55b5e6,
  Footer156a117850944aba8d05805c0d783e2f,
  Navigation68917b101ddb4a058159Befffbf1cf09,
  Title0d85b16fAd1848caA3e73ed76569487a,
  ContentSlugBb6b2f8182df420aB9e49b4bd16eb81a,
  PostSlugD129e3a32bc949d59e4771b2d00f4cf7,
  SecondaryMarketingHeroB788f542187246f4Bd45A69f7869279a,
  HomeHeader63f9b514F5ba40978766Afd4f559874d,
  FooterDd3f6204Ebff453097a903a5e4326781,
  SecondaryNav7e3eb7309c134650Ba79Fd03adb94a35,
  Logo5fff56cb6c94460aBc43Bc79c30a8ad2,
  HeaderTop649c336597064e508c236e8445a71150,
  Title9085a341A15f4e94Bdd16734e8c265cf,
  TitleE5109b3fA967483aB4d90d9d21e597f5,
  Header42f342ea8e2c4649A23a65768ee7c6aa,
  Links3631cc8a980240bf97f826bec5585ade,
  Header6158fc1f49334532863d5eef6dc332b3,
  Copyright73a9465fD49c4dcdA8c2A610bc5b38eb,
  FooterE39f100273a64e5eBabfF206888c718f,
  HeaderTop630cbdba33f94b63826f987f47ff2eb5,
  WidgetId20da09878c1f4ef3Ab07Ba8c258da05f,
  NavMenuSlug776c183751094a0499136357061672e7,
  Title7a335c7f7ca3419cAc4081ae59a0a1b2,
  DefaultFooter4b8dcb2bF08247c59f73F8034dd156a4,
  Hero708c43f1220c43d0Bb1b48f3743b241a,
  PostSlug534828a55045471780b2A71ee075785f,
  LinkedLogo3bb6758b889845d1B08d819192f894a4,
  Navigation6413825e4dfa49f09b4e0ba603f2ee45,
  Header3cff4f2f44fa4af8A5c7C7568bc344c8,
  WidgetId7a617a48Ac7e4082Bf63Df714cae41f2,
  Class470f706cFcae46f88f9f83b947630075,
  Title8a0e103bB2f942b6A0b4C7872849c753,
  AboutLevelingPage641ae0569abd48f29d3f9c71d8e5d235,
  Header5c56e81fD8f04b3e9ad59033059a4d9f,
  TitleCdd1ae51Ba484a0f8242Bedeee2b4646,
  HeaderTopB2429ff49dab4bba97ac524b884c5419,
  GroupflowBe26cd667f0144048e11Ccb9cea431c3,
  Href5d8e8b29D46b4017A8098bafe79daa30,
  Title6cc1b7dc22a64e0b9526B9d3866e88a8,
  Title8d05f90c0ce340269d22E2934b16d1ad,
  MessagePages09b626e64a4c418d8a0fA3dbea849cff,
  HeaderDbbbcd735bf6487bB73150cd350c4297,
  LinkedLogo202df3531b2c42c3865a67623d36f621,
  BackgroundImageF0a636a6265d4eec80faA6f503f6b0da,
  Footer56e6faef46e74e30Aca68ead0b3af4f7,
  NavMenuSlug8c30744a283d4d7cAfa6C6563dd423a7,
  TitleDf7d96f33faf49c68e144f5edfde06db,
  NavMenuSlug09a2ade923a94cc9Bf3a00cd694d7aa1,
  NavMenuSlug8053e75f937c4f6cB5727b44ea24fad5,
  HomePageHero6010d128Cf2b4d49B64b8cca6eb90db5,
  Header18cb0782A5444497A159B3e6c5e0a90b,
  Body8c550e5140fd4727A4988b2b2117f884,
  MarketingCards4e352a07C22c4d9f9c3a36c4d090b1eb,
  Title57823aedA915499dBc5e0c259f26a881,
  BodyA2ffd24b76f143048cd7Fe42ee8ad6d6,
  LabelEbb4a3e2D02141bb9043703a8d67c257,
  NavMenuSlug3bfdf42924174fddA74b03015781ab58,
  SecondaryMarketingHeader071575d230b74d21B83600e18eaa2b9d,
  WelcomeBackB8a496c318a84ac79722A6bf1c0d8fa2,
  Header62383db08bbe48fcB06e0fcd3644f590,
  ButtonAa76e7af69094a1a98d74946bfc94452,
  HeaderEa549741Dd8b4c31Ab372b1fde251c20,
  Quote71717e0f94df42329f36410bfaf9fb5e,
  Url1e05f3fcAa0f420686b4044167d4ed8f,
  PostSlug9350f7e72ae044d89545Dd17bbc716c8,
  HomePageAfd0152e8e8c4fe484216d3ab7e2651a,
  FooterB664451b7f274f159cc0Bf0c7719f523,
  Hero4d14adfdA01740beB60eDd784a8d9d68,
  Header168600fc59c2468785d8Cf6fe3399d84,
  BackgroundImage1c90a5f2Be4240b096b045413085921d,
  ImageUrlA37c932c0a4d46b1Ac11Ea849736a4b4,
  ClassE88adc3a77424e948096B0d788538bef,
  Label64bd4e2fD9a54a0087761ef3e0e4e90e
}

export default Components;