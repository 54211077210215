const ROUTES = {
  "/": {
    "name": "HomePageAfd0152e8e8c4fe484216d3ab7e2651a",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages8f5453433bfa41dc94318b238741f886",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage07b08a52460048d28e620e085cc7e853",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage641ae0569abd48f29d3f9c71d8e5d235",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage699c18cdE49740129cdc90dafbf8ea1b",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage3362c038B4a847a08d43C96a4401608c",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages3eb83dd07b7d43228274076046dd31d2",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages09b626e64a4c418d8a0fA3dbea849cff",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage527439f8Cfc845dd88bd06e7d32edd3c",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesB18f26c65a254b10876706a690036893",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;